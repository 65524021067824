



















































import {Component, Prop, Vue} from "vue-property-decorator";
import ControlPanelDownloadList from "./downloads/ControlPanelDownloadList.vue";
import TextInput from "../form/TextInput.vue";
import FormButton from "@/components/form/Button.vue";
import VueSelect from "vue-select";
import DownloadType from "../../models/downloads/DownloadType";
import ChangeDownloadOrderDTO from "../../models/downloads/ChangeDownloadOrderDTO";
import UploadDownloadDTO from "../../models/downloads/UploadDownloadDTO";
import EditDownloadDTO from "../../models/downloads/EditDownloadDTO";
import ReplaceDownloadFileDTO from "../../models/downloads/ReplaceDownloadFileDTO";
import DeleteDownloadDTO from "../../models/downloads/DeleteDownloadDTO";
import {captureException} from "@sentry/browser";

@Component({
  components: {
    ControlPanelDownloadList,
    TextInput,
    FormButton,
    VueSelect,
  },
  props: ["type"],
})
export default class DownloadsControlPanel extends Vue {
  @Prop(String)
  public readonly type: string;

  public protocolName: string = "";
  public selectedCategory: string = "";
  public protocolNameWarning = false;
  public selectedCategoryWarning = false;
  public fileInputWarning = false;

  public editDownloadName: string = "";
  public currentEditId = -1;
  public currentEditCategory = -1;
  public editProtocolNameWarning = false;

  get title() {
    switch (this.type) {
      case "protocols":
        return "Public protocols";
      case "other":
        return "Other downloads";
      default:
        return "";
    }
  }

  get typeEnum() {
    switch (this.type) {
      case "protocols":
        return DownloadType.Protocols;
      case "other":
        return DownloadType.Other;
    }
  }

  get categories() {
    return this.$store.state.downloads[this.type].categories;
  }

  public created() {
    this.$store.dispatch(`downloads/fetchDownloads`, this.typeEnum);
  }

  public orderChange(payload: any) {
    this.$store.dispatch(`downloads/changeDownloadOrder`, new ChangeDownloadOrderDTO(payload, this.typeEnum));
  }

  public validateNewDownload() {
    const fileInput = this.$refs.protocolFile as HTMLInputElement;
    this.protocolNameWarning = !this.protocolName;
    this.selectedCategoryWarning = !this.selectedCategory;
    this.fileInputWarning = !fileInput.value;

    return !this.protocolNameWarning && !this.selectedCategoryWarning && !this.fileInputWarning;
  }

  public uploadDownload() {
    if (this.validateNewDownload()) {
      const fileInput = this.$refs.protocolFile as HTMLInputElement;
      const formData = new FormData();
      formData.append("name", this.protocolName);
      formData.append("category_id", this.selectedCategory);
      formData.append("protocolFile", fileInput.files[0]);

      this.$store.dispatch("downloads/uploadDownload", new UploadDownloadDTO(formData, this.typeEnum)).then(() => {
        this.protocolName = "";
        fileInput.value = "";
        this.selectedCategory = "";
        this.$modal.hide("upload-protocol");
      }).catch((err) => {
        captureException(err);
        alert("Something went wrong");
      });
    }
  }

  public editDownload(e: any) {
    this.editDownloadName = e.name;
    this.currentEditId = e.id;
    this.currentEditCategory = e.categoryid;
    this.$modal.show("edit-protocol");
  }

  public validateEditDownload() {
    this.editProtocolNameWarning = !this.editDownloadName;

    return !this.editProtocolNameWarning;
  }

  public submitEdit() {
    const fileInput = this.$refs.editProtocolFile as HTMLInputElement;
    if (this.validateEditDownload()) {
      if (fileInput.value) {
        const formData = new FormData();
        formData.append("name", this.editDownloadName);
        formData.append("protocolFile", fileInput.files[0]);
        this.$store.dispatch("downloads/replaceDownloadFile",
          new ReplaceDownloadFileDTO(formData, this.currentEditId, this.currentEditCategory, this.typeEnum))
          .then(() => {
            fileInput.value = "";
            this.$modal.hide("edit-protocol");
          });
      } else {
        this.$store.dispatch("downloads/editDownload",
          new EditDownloadDTO(this.editDownloadName, this.currentEditId, this.currentEditCategory, this.typeEnum))
          .then(() => {
            this.$modal.hide("edit-protocol");
          });
      }
    }
  }

  public deleteProtocol() {
    if (confirm(`Are you sure you want to delete this download? This action can not be undone.`)) {
      this.$store.dispatch("downloads/deleteDownload",
        new DeleteDownloadDTO(this.currentEditId, this.currentEditCategory, this.typeEnum),
      ).then(() => {
        this.$modal.hide("edit-protocol");
      });
    }
  }

  public resetEditValues() {
    this.editDownloadName = "";
    this.currentEditId = -1;
  }
}
