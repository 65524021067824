import DownloadType from "./DownloadType";

export default class ReplaceDownloadFileDTO {
  public formData: FormData;
  public downloadType: DownloadType;
  public id: number;
  public categoryid: number;

  constructor(formData: FormData, id: number, categoryid: number, downloadType: DownloadType) {
    this.formData = formData;
    this.id = id;
    this.categoryid = categoryid;
    this.downloadType = downloadType;
  }
}
