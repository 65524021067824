import DownloadType from "./DownloadType";

export default class ChangeDownloadOrderDTO {
  public orderPayload: any;
  public downloadType: DownloadType;

  constructor(orderPayload: any, downloadType: DownloadType) {
    this.orderPayload = orderPayload;
    this.downloadType = downloadType;
  }
}
