import DownloadType from "./DownloadType";

export default class EditDownloadDTO {
  public name: string;
  public id: number;
  public categoryid: number;
  public downloadType: DownloadType;

  constructor(name: string, id: number, categoryid: number, downloadType: DownloadType) {
    this.name = name;
    this.id = id;
    this.categoryid = categoryid;
    this.downloadType = downloadType;
  }
}
