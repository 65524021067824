import DownloadType from "./DownloadType";

export default class UploadDownloadDTO {
  public formData: FormData;
  public downloadType: DownloadType;

  constructor(formData: FormData, downloadType: DownloadType) {
    this.formData = formData;
    this.downloadType = downloadType;
  }
}
