













import {Component, Prop, Vue} from "vue-property-decorator";


@Component({
  components: {},
  props: ["download"],
})
export default class ControlPanelDownload extends Vue {
  @Prop()
  public download!: any;

  public edit() {
    this.$emit("editDownload", this.download);
  }
}
