import DownloadType from "./DownloadType";

export default class DeleteDownloadDTO {
  public id: number;
  public categoryid: number;
  public downloadType: DownloadType;

  constructor(id: number, categoryid: number, downloadType: DownloadType) {
    this.id = id;
    this.categoryid = categoryid;
    this.downloadType = downloadType;
  }
}
