









import {Component, Prop, Vue} from "vue-property-decorator";
import ControlPanelDownload from "./ControlPanelDownload.vue";
import draggable from "vuedraggable";

@Component({
  components: {
    ControlPanelDownload,
    draggable,
  },
  props: ["list", "category"],
})
export default class ControlPanelDownloadList extends Vue {
  @Prop()
  public list!: string;
  @Prop()
  public category!: number;

  get downloads() {
    if (this.category) {
      return this.$store.getters["downloads/getCategory"](this.category, this.list);
    }
    return this.$store.state.downloads[this.list].list;
  }

  set downloads(val) {
    if (this.category) {
      this.$emit("changedOrder", {newList: val, category: this.category});
    } else {
      this.$emit("changedOrder", val);
    }
  }

  public editDownload(dl: any) {
    this.$emit("editDownload", dl);
  }
}
